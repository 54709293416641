//
<template>
  <div id="app3">
    <!-- 顶部 -->
    <Top :white="$store.state.model.black" ref="top"></Top>

    <div class="history_body">
      <!-- 左侧 -->
      <Leftside></Leftside>

      <!-- 右侧主题 -->
      <div class="history_body_right">
        <div class="his_r_title"><span>模板</span></div>
        <!-- 主体 -->
        <div class="his_r_body">
          <div class="none">
            <img src="../assets/img/none.png" />
            <span>暂时没有任何内容</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
let that;
let fetch;
let vuex;
import Top from '../components/Top'; //导航top
import Leftside from '../components/Leftside'; //左侧主题
export default {
  components: {
    Top,
    Leftside,
  },
  name: 'History',
  data() {
    return {};
  },
  created() {
    that = this;
    fetch = this.api.user;
    vuex = this.$store.state;
    that;
    fetch;
    vuex;
  },
  mounted() {
    that.YS.closeallWin(); //关闭所有弹窗
  },
  methods: {},
};
</script>
<style lang="scss" scope>
// .top-nav {
//   background: white;
// }
// .tag-input {
//   background: #eeeeee;
//   // border: 1px solid rgba(255, 255, 255, 0.43137254901960786);
// }

// .tag-span,
// .right-content span {
//   color: rgba(51, 51, 51, 1);
// }
// .bubble {
//   background: #91d6ff;
// }
</style>
